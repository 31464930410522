import { FinanceStatusEnum } from '../__generated__/globalTypes'

export const FINANCE_STATUS_OPTIONS = [
  {
    value: FinanceStatusEnum.NONE,
    label: 'None',
    data: {
      cy: `order-finance-status-select-option-${FinanceStatusEnum.NONE.toLowerCase()}`,
    },
  },
  {
    value: FinanceStatusEnum.SENT_TO_FINANCE,
    label: 'Sent to finance',
    data: {
      cy: `order-finance-status-select-option-${FinanceStatusEnum.SENT_TO_FINANCE.toLowerCase()}`,
    },
  },
  {
    value: FinanceStatusEnum.SENT_TO_S4,
    label: 'Sent to S4',
    data: {
      cy: `order-finance-status-select-option-${FinanceStatusEnum.SENT_TO_S4.toLowerCase()}`,
    },
  },
  {
    value: FinanceStatusEnum.ACCEPTED_BY_FINANCE,
    label: 'Accepted by finance',
    data: {
      cy: `order-finance-status-select-option-${FinanceStatusEnum.ACCEPTED_BY_FINANCE.toLowerCase()}`,
    },
  },
  {
    value: FinanceStatusEnum.ACCEPTED_BY_FINANCE_WITH_WARNINGS,
    label: 'Requires Attention in SAP',
    data: {
      cy: `order-finance-status-select-option-${FinanceStatusEnum.ACCEPTED_BY_FINANCE_WITH_WARNINGS.toLowerCase()}`,
    },
  },
  {
    value: FinanceStatusEnum.REJECTED_BY_FINANCE,
    label: 'Rejected by finance',
    data: {
      cy: `order-finance-status-select-option-${FinanceStatusEnum.REJECTED_BY_FINANCE.toLowerCase()}`,
    },
  },
  {
    value: FinanceStatusEnum.INVOICED,
    label: 'Invoiced',
    data: {
      cy: `order-finance-status-select-option-${FinanceStatusEnum.INVOICED.toLowerCase()}`,
    },
  },
  {
    value: FinanceStatusEnum.PAID,
    label: 'Paid',
    data: {
      cy: `order-finance-status-select-option-${FinanceStatusEnum.PAID.toLowerCase()}`,
    },
  },
  {
    value: FinanceStatusEnum.CREDIT_REQUESTED,
    label: 'Credit approval requested',
    data: {
      cy: `order-finance-status-select-option-${FinanceStatusEnum.CREDIT_REQUESTED.toLowerCase()}`,
    },
  },
  {
    value: FinanceStatusEnum.CREDIT_APPROVED,
    label: 'Sales approved credit',
    data: {
      cy: `order-finance-status-select-option-${FinanceStatusEnum.CREDIT_APPROVED.toLowerCase()}`,
    },
  },
  {
    value: FinanceStatusEnum.CREDITED,
    label: 'Credited',
    data: {
      cy: `order-finance-status-select-option-${FinanceStatusEnum.CREDITED.toLowerCase()}`,
    },
  },
  {
    value: FinanceStatusEnum.CREDIT_REJECTED_BY_FINANCE,
    label: 'Credit rejected by finance',
    data: {
      cy: `order-finance-status-select-option-${FinanceStatusEnum.CREDIT_REJECTED_BY_FINANCE.toLowerCase()}`,
    },
  },
  {
    value: FinanceStatusEnum.CANCEL_CREDIT_REQUEST,
    label: 'Cancel credit request',
    data: {
      cy: `order-finance-status-select-option-${FinanceStatusEnum.CANCEL_CREDIT_REQUEST.toLowerCase()}`,
    },
  },
]

interface FinanceStates {
  [key: string]: string
}

export const FINANCE_STATES: FinanceStates = {
  ...FinanceStatusEnum,
}
