import gql from 'graphql-tag'
import { FULL_ADDRESS_FRAGMENT } from './AddressFragments'
import { QUOTE_FRAGMENT } from './QuoteFragments'
import { PURCHASE_ORDER_FRAGMENT } from './PurchaseOrderFragments'
import { SAP_BILLING_DETAIL_FRAGMENT } from './SapBillingDetailFragments'
import { FULL_TEAM_MEMBER_FRAGMENT } from './TeamMemberFragments'

export const FULL_CUSTOMER_FRAGMENT = gql`
  fragment FullCustomerFragment on Customer {
    id
    updatedAt
    name
    currencyCode
    region {
      id
      code
      name
    }
    financeId
    nordionAccountNumber
    archivedAt
    preferredAddressId
    billingBlock
    teamMembers {
      ...FullTeamMemberFragment
    }
    addresses {
      ...AddressFragment
    }
    quote {
      ...QuoteFragment
    }
    purchaseOrder {
      ...PurchaseOrderFragment
    }
    sapBillingDetail {
      ...SapBillingDetailFragment
    }
    assignedUsers {
      id
      name
    }
    freightPrice
    simplicityPerPlan
    sla
    sendOrdersToSap
    country
    attachments {
      id
      filename
      extension
      url
      downloadUrl
      user {
        name
      }
    }
    businessType
    distributor {
      id
      name
      region {
        id
        code
        name
      }
      addresses {
        ...AddressFragment
      }
      quote {
        ...QuoteFragment
      }
      purchaseOrder {
        ...PurchaseOrderFragment
      }
      sapBillingDetail {
        ...SapBillingDetailFragment
      }
      currencyCode
      freightPrice
      simplicityPerPlan
      sla
      businessType
      billingBlock
      sendOrdersToSap
    }
    alertData
    specialDeal
    bwxtShippingNote
    inheritAddressesFromDistributor
    inheritFinancialInfoFromDistributor
    restricted
    customerViaDistributors {
      id
      name
      archivedAt
    }
  }
  ${FULL_ADDRESS_FRAGMENT}
  ${QUOTE_FRAGMENT}
  ${PURCHASE_ORDER_FRAGMENT}
  ${SAP_BILLING_DETAIL_FRAGMENT}
  ${FULL_TEAM_MEMBER_FRAGMENT}
`
