import gql from 'graphql-tag'

export const SURPLUS_BALANCES_QUERY = gql`
  query SurplusBalances(
    $calibrationDate: Date!
    $regionCode: RegionCodeEnum
    $aboveZero: Boolean
    $surplusType: SurplusEntryTypeEnum!
  ) {
    surplusBalances(
      calibrationDate: $calibrationDate
      regionCode: $regionCode
      aboveZero: $aboveZero
      surplusType: $surplusType
    ) {
      id
      dosage
      available
      pending
      regionCode
      pendingForOrders
      pendingForOrdersWithIto
      pendingForBuildPlans
      pendingForInventoryTransferOrders
      calibrationDate
      surplusType
    }
  }
`

export const BWXT_PENDING_SURPLUS_QUERY = gql`
  query BwxtPendingInventory(
    $calibrationDate: Date!
    $regionCode: RegionCodeEnum!
  ) {
    bwxtPendingInventory(
      calibrationDate: $calibrationDate
      regionCode: $regionCode
    ) {
      items {
        __typename
      dosage
      demand
      spares
      mnxSpares
      backups
      }
    }
  }
`

export const CHECK_MOVEABLE_SURPLUS_QUERY = gql`
  query CheckMoveableSurplus(
    $calibrationDate: Date!
    $regionCode: RegionCodeEnum!
    $dosage: Float!
    $surplusType: SurplusEntryTypeEnum!
    $sourceType: String!
  ) {
    checkMoveableSurplus(
      calibrationDate: $calibrationDate
      regionCode: $regionCode
      dosage: $dosage
      surplusType: $surplusType
      sourceType: $sourceType
    )
  }
`

export const CHECK_MOVEABLE_ITO_SURPLUS_QUERY = gql`
  query CheckMoveableItoSurplus(
    $calibrationDate: Date!
    $regionCode: RegionCodeEnum!
    $dosage: Float!
    $surplusType: SurplusEntryTypeEnum!
    $sourceType: String!
  ) {
    checkMoveableSurplus(
      calibrationDate: $calibrationDate
      regionCode: $regionCode
      dosage: $dosage
      surplusType: $surplusType
      sourceType: $sourceType
    )
  }
`

export const CHECK_SURPLUS_QUERY = gql`
  query CheckSurplus(
    $regionCode: RegionCodeEnum!
    $treatments: [CheckTreatmentInput!]!
    $currentSurplusTreatments: CurrentTreatmentInput
  ) {
    checkSurplus(
      regionCode: $regionCode
      treatments: $treatments
      currentSurplusTreatments: $currentSurplusTreatments
    ) {
      bwxtSurplusAvailable
      mnxSurplusAvailable
      error
      message
    }
  }
`
export const RESERVABLE_MNX_SURPLUS_INVENTORY_QUERY = gql`
  query ReservableMnxSurplusInventory(
    $calibrationDate: Date!
  ) {
    reservableMnxSurplusInventory(
      calibrationDate: $calibrationDate
    ) {
      expectedArrivalDate
      regionCode
      dosage
      reservableVials
      reservedVials
    }
  }
`

export const CHECK_IN_TRANSIT_QUERY = gql`
  query CheckReservableSurplus(
    $regionCode: RegionCodeEnum!
    $treatment: CheckTreatmentInput!
    $shipDate: Date!
    $currentSurplusTreatments: CurrentTreatmentInput
    $orderId: String
  ) {
    checkReservableSurplus(
      regionCode: $regionCode
      treatment: $treatment
      shipDate: $shipDate
      currentSurplusTreatments: $currentSurplusTreatments
      orderId: $orderId
    ) {
      surplusAvailable
      error
      message
    }
  }
`
