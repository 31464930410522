import gql from 'graphql-tag'
import { FULL_PRODUCT_FRAGMENT } from '../fragments'

export const ADDRESS_ACCESSORIES_QUERY = gql`
  query AddressAccessoriesQuery(
    $id: ID!
    $country: String
  ) {
    address(
      id: $id
      country: $country
    ) {
      id
      addressAccessories {
        id
        freight
        freightType
        itemDescription
        itemNumber
        archivedAt
      }
    }
  }
`

export const PRODUCT_SELECT_QUERY = gql`
  query ProductSelectQuery(
    $type: ProductTypeEnum!
    $limit: Int!
    $regionCode: RegionCodeEnum
    $orderType: OrderTypeEnum
    $country: String
  ) {
    products(
      type: $type
      limit: $limit
      regionCode: $regionCode
      orderType: $orderType
      country: $country
    ) {
      totalPages
      items {
        id
        itemDescription
        itemNumber
        dosage
      }
    }
  }
`

export const PRODUCTS_QUERY = gql`
  query GetProducts(
    $search: String
    $page: Int
    $limit: Int
    $showArchived: Boolean
  ) {
    products(
      search: $search
      page: $page
      limit: $limit
      showArchived: $showArchived
    ) {
      totalPages
      items {
        id
        productType
        region {
          id
          name
        }
        itemNumber
        itemDescription
        dosage
        itemUsageType
        sapMaterialNumber
        freight
        freightType
        createdAt
        archivedAt
      }
    }
  }
`

export const PRODUCT_QUERY = gql`
  query GetProduct($id: ID!) {
    product(id: $id) {
      ...FullProduct
    }
  }
  ${FULL_PRODUCT_FRAGMENT}
`

export const TREATMENT_DOSAGES_QUERY = gql`
  query TreatmentDosages($ids: [ID!]!) {
    treatmentDosages(ids: $ids)
  }
`

export const RESTRICTED_ACCESSORIES_QUERY = gql`
  query RestrictedAccessories($country: String!) {
    restrictedAccessories(country: $country) {
      items {
        id
        itemDescription
        itemNumber
        dosage
      }
    }
  }
`
