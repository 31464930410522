import {
  RegionCodeEnum,
  SurplusEntryTypeEnum,
} from '../__generated__/globalTypes'

interface Entry {
  calibrationDate: string
  dosage: number
  regionCode: RegionCodeEnum
  surplusType: SurplusEntryTypeEnum
}

export const compareEntries = (entry1: Entry, entry2?: Entry): boolean => {
  if (!entry1 || !entry2) return false

  return (
    entry1.calibrationDate === entry2.calibrationDate &&
    entry1.dosage === entry2.dosage &&
    entry1.regionCode === entry2.regionCode &&
    entry1.surplusType === entry2.surplusType
  )
}
