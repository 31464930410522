import gql from 'graphql-tag'
import {HISTORY_FRAGMENT} from "../fragments";

export const CALIBRATION_DATE_CLOSED_QUERY = gql`
  query CalibrationDateClosed($date: Date!) {
    calibrationDate(date: $date) {
      id
      closedAt
      lastVersionOfClosedBp { ...History }
    }
  }
  ${HISTORY_FRAGMENT}
`
export const CALIBRATION_DATE_ADDITIONAL_INVENTORY_QUERY = gql`
  query CalibrationDateAdditionalInventoryQuery($date: Date!) {
    calibrationDate(date: $date) {
      id
      additionalInventoryStatus
      lastVersionOfAdditionalInventoryStatus { ...History }
    }
  }
  ${HISTORY_FRAGMENT}
`

export const ACTIVE_CALIBRATION_DATES_QUERY = gql`
  query ActiveCalibrationDates {
    activeCalibrationDates
  }
`

export const CALIBRATION_DATE_OPEN_QUERY = gql`
  query CalibrationDateOpen($date: Date, $dates: [Date!]) {
    calibrationDateOpen(date: $date, dates: $dates)
  }
`
