import { SourceTypeEnum } from '../__generated__/globalTypes'

export const SOURCE_TYPES_OPTIONS = [
  {
    value: SourceTypeEnum.THERASPHERE_NOW,
    label: 'Therasphere Now',
    data: { value: SourceTypeEnum.THERASPHERE_NOW },
  },
  {
    value: SourceTypeEnum.WEB_PORTAL,
    label: 'BSci Web Portal',
    data: { value: SourceTypeEnum.WEB_PORTAL },
  },
  {
    value: SourceTypeEnum.ORDER_FORM_EMAIL,
    label: 'Order Form - Email',
    data: { value: SourceTypeEnum.ORDER_FORM_EMAIL },
  },
  {
    value: SourceTypeEnum.ORDER_FORM_FAX,
    label: 'Order Form - Fax',
    data: { value: SourceTypeEnum.ORDER_FORM_FAX },
  },
  {
    value: SourceTypeEnum.IDOC,
    label: 'iDOC',
    data: { value: SourceTypeEnum.IDOC },
  },
  {
    value: SourceTypeEnum.TO_FOLLOW,
    label: 'To Follow',
    data: { value: SourceTypeEnum.TO_FOLLOW },
  },
  {
    value: SourceTypeEnum.EMAIL_SETS,
    label: 'Email - Sets',
    data: { value: SourceTypeEnum.EMAIL_SETS },
  },
  {
    value: SourceTypeEnum.THERASPHERE360,
    label: 'Therasphere360',
    data: { value: SourceTypeEnum.THERASPHERE360 },
  }
]
