import { SalesOrganizationRoutingEnum } from '../__generated__/globalTypes'

export const SAP_ROUTINGS_LABELS = {
  sap_ecc: "SAP ECC",
  sap_s4: "SAP S4"
}

export const SAP_ROUTINGS_OPTIONS = [
  { value: SalesOrganizationRoutingEnum.sap_ecc, label: SAP_ROUTINGS_LABELS.sap_ecc },
  { value: SalesOrganizationRoutingEnum.sap_s4, label: SAP_ROUTINGS_LABELS.sap_s4 }
]
